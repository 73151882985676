import { render, staticRenderFns } from "./top-container.vue?vue&type=template&id=388f8b17&scoped=true&"
import script from "./top-container.vue?vue&type=script&lang=ts&"
export * from "./top-container.vue?vue&type=script&lang=ts&"
import style0 from "./top-container.vue?vue&type=style&index=0&id=388f8b17&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "388f8b17",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VImg,VRow,VSheet,VSkeletonLoader,VSpacer})
