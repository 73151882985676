








































































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { SummaryViewModel } from '../../viewmodels/summary-viewmodel'

@Observer
@Component({
  components: {
    'link-item': () => import('./link.vue'),
    'tag-item': () => import('./tag.vue'),
    'like-item': () => import('./like.vue'),
  },
})
export default class TopContainer extends Vue {
  @Inject() vm!: SummaryViewModel
}
